import {
  Box,
  Button,
  InputField,
  Table,
  Tag,
  Avatar,
  Text,
  Pagination,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import { openModal } from "@axazara/raiton-molecules";
import {
  CloseCircleFill,
  ProfileAddFill,
  SearchNormal1Outline,
  EyeOutline,
  TickCircleFill,
} from "@axazara/raiton-icons";
import React from "react";
import { AddStaff } from "./AddStaff";
import { Link } from "react-router-dom";
import { useGetStaffsData } from "../services";
import { useDebouncedValue } from "@axazara/raiton-utils";

export function StaffsTab() {
  const searchInputRef = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: staffs, isLoading } = useGetStaffsData({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />

      <Box className="p-5">
        <Box className="flex justify-between mb-5 space-x-5">
          <InputField
            placeholder="Search staff"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            className="w-full"
            onChange={(e) => setSearchQuery(e.target.value)}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-gray-600"
                  onClick={() => {
                    setSearchQuery("");
                    if (searchInputRef.current)
                      (searchInputRef.current as any).value = "";
                  }}
                />
              ) : null
            }
            classNames={{
              input: "rounded-xl",
            }}
          />
          <Button
            onClick={() =>
              openModal({
                children: <AddStaff />,
                size: "lg",
              })
            }
            size="xs"
            rightIcon={<ProfileAddFill className="w-5 h-5" />}
            className="text-p-01-semibold"
          >
            Add staffs member
          </Button>
        </Box>
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse border border-neutral-80 border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell className="w-96">Name</Table.HeadCell>
              <Table.HeadCell>Email</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>View</Table.HeadCell>
            </Table.Head>
            {staffs &&
              staffs?.data.map((staff) => (
                <Table.Row
                  key={staff.id}
                  className="border-b border-neutral-90"
                >
                  <Table.Cell>
                    <Box className="flex items-center">
                      <Avatar
                        src={staff.profile_photo_url}
                        size={48}
                        className="mr-4"
                      />
                      <Text className="text-neutral-20 text-p-01-medium">
                        {staff.last_name} {staff.first_name}
                      </Text>
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    <Text
                      component="span"
                      className="text-p-01 text-neutral-40"
                    >
                      {staff.email}
                    </Text>
                  </Table.Cell>
                  <Table.Cell>
                    {staff.is_active ? (
                      <Tag
                        className="text-p-01 normal-case bg-success-90 py-3"
                        size="lg"
                        leftSection={
                          <TickCircleFill className="w-4 h-4 text-success-30" />
                        }
                      >
                        <Text component="span" className="text-success-30">
                          Active
                        </Text>
                      </Tag>
                    ) : (
                      <Tag
                        className="text-p-01 normal-case bg-critical-50 py-3"
                        size="lg"
                        leftSection={
                          <CloseCircleFill className="w-4 h-4 text-white" />
                        }
                      >
                        <Text component="span" className="text-white">
                          Inactive
                        </Text>
                      </Tag>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Box
                      state={staff}
                      component={Link}
                      to={`/staffs/${staff.id}`}
                    >
                      <Button
                        variant="subtle"
                        size="xs"
                        leftIcon={
                          <EyeOutline className="w-6 h-6 text-primary-60 mr-1" />
                        }
                        className="text-p-02-semibold"
                      >
                        View
                      </Button>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table>
        </Box>
        <Box className="flex w-full justify-end items-center mb-16 pt-8 px-6">
          <Pagination
            size="xl"
            radius={0}
            spacing={0}
            color="green"
            initialPage={1}
            onChange={setPage}
            page={page}
            total={staffs?.pagination.last_page ?? 0}
            style={{ gap: 0 }}
            styles={() => ({
              item: {
                fontSize: 14,
                "&:focus": {
                  outline: "none",
                },
              },
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}
