import { Edit2Fill, GridEditFill, InfoCircleFill } from "@axazara/raiton-icons";
import { useForm, showNotification } from "@axazara/raiton-molecules";
import { Box, Button, InputField } from "@axazara/raiton-atoms";
import { useUpdateTemplate } from "../service";
import { TemplateModel } from "utilities";
import { useTranslation } from "react-i18next";

export function TemplateInfosForm({ template }: { template: TemplateModel }) {
  const templateMutation = useUpdateTemplate(template.id);

  const editTemplateForm = useForm({
    initialValues: {
      name: template.name,
      subject: template.subject,
    },
  });

  const { t } = useTranslation();

  const handleSubmit = async (values: Partial<TemplateModel>) => {
    try {
      await templateMutation.mutateAsync(values);

      showNotification({
        title: t("success"),
        message: t("template_updated"),
        color: "success",
        autoClose: 5000,
      });
    } catch (error) {
      showNotification({
        title: t("error"),
        message: (error as any).message,
        color: "error",
      });
    }
  };

  return (
    <Box className="flex w-full max-w-xl mx-auto mt-12">
      <Box
        component="form"
        className="w-full"
        onSubmit={editTemplateForm.onSubmit(handleSubmit)}
      >
        <Box className="px-8 pt-12 w-full space-y-6">
          <Box>
            <InputField
              label={t("template_name")}
              icon={<Edit2Fill className="w-5 h-5 text-neutral-40" />}
              size="xs"
              radius={12}
              {...editTemplateForm.getInputProps("name")}
            />
          </Box>
          <Box>
            <InputField
              label={t("template_subject")}
              icon={<InfoCircleFill className="w-5 h-5 text-neutral-40" />}
              size="xs"
              radius={12}
              {...editTemplateForm.getInputProps("subject")}
            />
          </Box>
          <Box className="flex mt-12 justify-end">
            <Button
              type="submit"
              size="xs"
              disabled={!editTemplateForm.isDirty()}
              loading={templateMutation.isLoading}
              leftIcon={<GridEditFill className="w-4 h-4" />}
            >
              {t("update_information")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
