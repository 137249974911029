import {
  InfoCircleFill,
  DocumentText1Fill,
  Edit2Fill,
} from "@axazara/raiton-icons";
import { Box, Button, InputField, Select } from "@axazara/raiton-atoms";
import { useForm, closeModal } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useTranslation } from "react-i18next";
import { useAddTemplateModel, useGetTemplateModelCategories } from "../service";
import { AddTemplateModelPayload } from "utilities";

export function AddTemplateModal() {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useAddTemplateModel();
  const { data: templateModelsCategories } = useGetTemplateModelCategories({});
  const templateForm = useForm<AddTemplateModelPayload>({
    initialValues: {
      name: "",
      subject: "",
      category: "",
    },
  });

  const handleSubmit = async (values: AddTemplateModelPayload) => {
    try {
      const response = await mutateAsync(values);
      templateForm.reset();
      closeModal("");
      showSuccessNotification((response as any)?.message);
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  };

  return (
    <Box component="form" onSubmit={templateForm.onSubmit(handleSubmit)}>
      <Box className="space-y-6">
        <InputField
          size="xs"
          radius={12}
          label={t("template_name")}
          {...templateForm.getInputProps("name")}
          icon={<Edit2Fill className="w-5 h-5 text-neutral-40" />}
        />
        <InputField
          size="xs"
          radius={12}
          label={t("template_object")}
          {...templateForm.getInputProps("subject")}
          icon={<InfoCircleFill className="w-5 h-5 text-neutral-40" />}
        />

        {templateModelsCategories && (
          <Select
            size="xs"
            radius={12}
            data={templateModelsCategories}
            label={t("template_category")}
            {...templateForm.getInputProps("category")}
            icon={<InfoCircleFill className="w-5 h-5 text-neutral-40" />}
          />
        )}
      </Box>

      <Box className="flex justify-end mt-8">
        <Button
          type="submit"
          size="xs"
          radius={12}
          loading={isLoading}
          leftIcon={<DocumentText1Fill className="w-5 h-5" />}
        >
          {t("add_template")}
        </Button>
      </Box>
    </Box>
  );
}
