import { ApiEndpoints } from "@/configs";
import { useFetch } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedApps, PaginatedUsers, User } from "utilities";

export interface UserWithMessage extends User {
  message: string;
}

export const useGetUser = (id: string) =>
  useFetch<User>(`${ApiEndpoints.users}/${id}`);
export const useGetUserInformation = (id: string) =>
  useFetch<User>(`${ApiEndpoints.users}/${id}`);

export const useGetUsers = (
  { page, search }: { page: number; search: string } = {
    page: 1,
    search: "",
  },
) => {
  const params = buildFetchParams({ page, search });

  return useFetch<PaginatedUsers>(ApiEndpoints.users, params);
};

export const useGetUserApps = (
  { page, userId }: { page: number; userId: string } = {
    page: 1,
    userId: "",
  },
) => {
  const params = buildFetchParams({ page });
  return useFetch<PaginatedApps>(
    `${ApiEndpoints.users}/${userId}/apps`,
    params,
  );
};
