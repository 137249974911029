import { Box, Text, Code } from "@axazara/raiton-atoms";
import { ProfileCircleFill } from "@axazara/raiton-icons";
import { Activity, formatDate } from "utilities";

export function StaffActivity({ staffActivity }: { staffActivity: Activity }) {
  return (
    <Box>
      <Box className="flex justify-between mb-12">
        <Box className="flex flex-col space-y-2">
          <Text component="span" className="text-neutral-50 text-p-01-semibold">
            ID
          </Text>

          <Text
            component="span"
            className="text-p-01 text-ellipsis overflow-hidden"
          >
            {staffActivity.id.slice(0, 10)}
          </Text>
        </Box>
        <Box className="flex flex-col space-y-2">
          <Text component="span" className="text-neutral-50 text-p-01-semibold">
            Date
          </Text>
          <Text component="span" className="text-p-01">
            {formatDate(staffActivity.updated_at, "en")}
          </Text>
        </Box>
      </Box>
      <Text component="span" className="text-neutral-50 text-p-01-semibold">
        Performed by
      </Text>
      <Box className="flex justify-between mb-12">
        <Box className="flex items-center">
          <Box>
            <ProfileCircleFill className="w-16 h-16" />
          </Box>
          <Box className="space-y-1.5">
            <Text component="span" className="text-p-01-semibold">
              {staffActivity.staff.full_name}
            </Text>
            <Box className="text-neutral-40">
              <Text className="text-p-01-medium">
                {staffActivity.staff.email}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="flex justify-between mb-12">
        <Box className="flex flex-col space-y-2">
          <Text component="span" className="text-neutral-50 text-p-01">
            Action
          </Text>
          <Text component="span" className="text-p-01">
            {staffActivity.action}
          </Text>
        </Box>
        <Box className="flex flex-col space-y-2">
          <Text component="span" className="text-neutral-50 text-p-01">
            IP Address
          </Text>
          <Text component="span" className="text-p-01">
            {staffActivity.ip}
          </Text>
        </Box>
      </Box>
      <Box className="flex flex-col space-y-2 mb-12">
        <Text component="span" className="text-neutral-50 text-p-01">
          User Agent
        </Text>
        <Text component="span" className="text-p-01">
          {staffActivity.user_agent}
        </Text>
      </Box>
      <Box>
        <Text component="span" className="text-neutral-50 text-p-01">
          Data
        </Text>
        <Code block>{JSON.stringify(staffActivity?.data, null, 2)}</Code>
      </Box>
    </Box>
  );
}
