import { Navigate, Route, Routes } from "react-router-dom";
import { AuthLayout, PrivateOutlet, RootLayout } from "@/layouts";
import {
  AppDetails,
  Apps,
  Invitation,
  Login,
  NotFound,
  StaffDetails,
  Staffs,
  TemplateModelDetails,
  TemplateModelEditor,
  TemplatesModels,
  Users,
  UsersDetails,
} from "@/features";
import { appPaths } from "@/configs";

export function AppRoutes() {
  return (
    <Routes>
      <Route path={appPaths.root} element={<PrivateOutlet />}>
        <Route path={appPaths.root} element={<RootLayout />}>
          <Route
            path={appPaths.root}
            element={<Navigate to={appPaths.users.path} />}
          />
          <Route path={appPaths.users.name} index element={<Users />} />
          <Route path={appPaths.usersDetails.path} element={<UsersDetails />} />
          <Route path={appPaths.staffs.name} element={<Staffs />} />
          <Route path={appPaths.StaffDetails.path} element={<StaffDetails />} />
          <Route path={appPaths.apps.name} element={<Apps />} />
          <Route path={appPaths.appDetails.path} element={<AppDetails />} />
          <Route path={appPaths.templates.path} element={<TemplatesModels />} />
          <Route
            path={appPaths.templateDetails.path}
            element={<TemplateModelDetails />}
          />
          <Route
            path={appPaths.templateDetailsEdit.path}
            element={<TemplateModelEditor />}
          />
        </Route>
      </Route>
      <Route path={appPaths.auth} element={<AuthLayout />}>
        <Route path={appPaths.login.name} element={<Login />} index />
      </Route>
      <Route path={appPaths.invitation.path} element={<Invitation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
