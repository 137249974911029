import {
  ActionIcon,
  Avatar,
  Box,
  Divider,
  LoadingOverlay,
  Table,
  Tag,
  Text,
} from "@axazara/raiton-atoms";
import { Link } from "react-router-dom";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { User } from "utilities";
import { useGetUserApps } from "@/features/Users";
import { useTranslation } from "react-i18next";

export function UserAppsTab({ user }: { user: User }) {
  const { t } = useTranslation();
  const {
    data: apps,
    isLoading,
    isError,
    error,
  } = useGetUserApps({ userId: user.id, page: 1 });

  if (isError) return <h1>Error {error.toString()}</h1>;

  return (
    <Box className="p-5">
      <LoadingOverlay visible={isLoading} />
      <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
        <Table className="rounded-t-2xl overflow-hidden border-collapse border">
          <Table.Head className="border-b border-neutral-80 text-neutral-20 !bg-neutral text-p-01-semibold">
            <Table.HeadCell>ID</Table.HeadCell>
            <Table.HeadCell>{t("app")}</Table.HeadCell>
            <Table.HeadCell>{t("status")}</Table.HeadCell>
            <Table.HeadCell>{t("role")}</Table.HeadCell>
            <Table.HeadCell>{t("created_at")}</Table.HeadCell>
            <Table.HeadCell className="flex justify-end">View</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-neutral-80">
            {apps &&
              apps.data.length > 0 &&
              apps.data.map((app, index) => (
                <Table.Row className="bg-white" key={app.id}>
                  <Table.Cell>
                    <Text className="text-p-01-medium">{index}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Box className="md:flex-row flex flex-col items-center space-x-4">
                      <Avatar size={32} />
                      <Box>
                        <Text className="text-p-01-semibold text-neutral-20">
                          {app.name}
                        </Text>
                      </Box>
                    </Box>
                  </Table.Cell>
                  <Table.Cell>
                    {app.is_active ? (
                      <Tag
                        className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
                        size="lg"
                      >
                        <Text
                          component="span"
                          className="text-critical-40 text-p-01-semibold"
                        >
                          Disabled
                        </Text>
                      </Tag>
                    ) : (
                      <Tag
                        className="text-p-01-semibold normal-case bg-success-90 py-3"
                        size="lg"
                      >
                        <Text
                          component="span"
                          className="text-success-30 text-p-01-semibold"
                        >
                          Active
                        </Text>
                      </Tag>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>27 Sep 2023</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Box
                      component={Link}
                      to="/users-details"
                      className="cursor-pointer flex justify-end"
                    >
                      <ActionIcon
                        color="primary"
                        className="p-0.5"
                        size="xl"
                        radius={100}
                      >
                        <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                      </ActionIcon>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Divider color="neutral.9" />
      </Box>
    </Box>
  );
}
