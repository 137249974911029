import * as React from "react";

interface MetaContextType {
  title: string;
  description: string;
}

interface MetaContextActionType {
  type: "SET_METADATA";
  payload: {
    title: string;
    description: string;
  };
}
const defaultMetaContextValue = {
  title: "Mailzeet",
  description: "Send mail easy peazy",
};

const MetaContext = React.createContext<MetaContextType>(
  defaultMetaContextValue,
);
export const MetaDispatchContext =
  React.createContext<React.Dispatch<MetaContextActionType> | null>(null);

export function MetaContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [appData, dispatch] = React.useReducer(
    appReducer,
    defaultMetaContextValue,
  );
  return (
    <MetaContext.Provider value={appData}>
      <MetaDispatchContext.Provider value={dispatch}>
        {children}
      </MetaDispatchContext.Provider>
    </MetaContext.Provider>
  );
}

function appReducer(state: MetaContextType, action: MetaContextActionType) {
  switch (action.type) {
    case "SET_METADATA":
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
      };
    default:
      return state;
  }
}

export const useMetaContext = () => React.useContext(MetaContext);
export const useMetaDispatchContext = () =>
  React.useContext(MetaDispatchContext);
