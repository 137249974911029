import { getEnvConfigFactory } from "@axazara/raiton-utils";

const currentEnv: string = process.env.VITE_AXAZARA_ENV || "dev";

const getEnvConfig = getEnvConfigFactory(currentEnv);

export const appConfig = {
  APP_ENV: getEnvConfig({
    dev: "dev",
    beta: "beta",
    alpha: "alpha",
    prod: "prod",
  }),

  MAILZEET_API_DOMAIN: getEnvConfig({
    dev: "https://mailzeet-api.axaship.com",
    beta: "https://mailzeet-api.axaship.com",
    prod: process.env.VITE_MAILZEET_API_DOMAIN,
  }),
};
