import { Box, Text, Divider, Tabs, ActionIcon } from "@axazara/raiton-atoms";
import {
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  Grid2Outline,
  PeopleOutline,
} from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { appPaths } from "@/configs";
import { useGetApp } from "../services";
import { AppInformationTab, AppOwnerTab, AppTeamsTab } from "../components";

export function AppDetails() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.apps.path} />;
  }

  return <AppDetailsRenderer id={id} />;
}

function AppDetailsRenderer({ id }: { id: string }) {
  const { data: app, isError } = useGetApp(id);

  const navigate = useNavigate();

  if (isError) return <Navigate to={appPaths.apps.path} />;

  return (
    <Box className="pb-5">
      <Box className="flex flex-col sticky z-20 top-0 bg-white">
        <Box className="flex p-6 space-x-4 items-center">
          <ActionIcon radius={100} onClick={() => navigate(-1)}>
            <ArrowCircleLeftOutline className="w-6 h-6" />
          </ActionIcon>
          <Box className="flex flex-row items-center space-x-4">
            <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center">
              <Grid2Outline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-heading-05-bold">{app?.name}</Text>
          </Box>
        </Box>
        <Divider color="neutral.9" className={"mb-12 border-neutral-90"} />
      </Box>
      <Box className="p-6">
        <Tabs defaultValue="information" className="sticky top-0 z-10 bg-white">
          <Tabs.List className="mb-8 w-max mx-auto">
            <Tabs.Tab
              className="text-p-02-semibold w-full"
              value="information"
              icon={<TaskSquareOutline className="w-6 h-6" />}
            >
              Information
            </Tabs.Tab>
            <Tabs.Tab
              className="text-p-02-semibold w-full"
              value="owner"
              icon={<TaskSquareOutline className="w-6 h-6" />}
            >
              Owner
            </Tabs.Tab>

            <Tabs.Tab
              className="text-p-02-semibold w-full"
              value="teams"
              icon={<PeopleOutline className="w-6 h-6" />}
            >
              Teams
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="information">
            {app && <AppInformationTab app={app} />}
          </Tabs.Panel>

          <Tabs.Panel value="owner">
            {app && <AppOwnerTab app={app} />}
          </Tabs.Panel>

          <Tabs.Panel value="teams">
            {app && <AppTeamsTab app={app} />}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
