export default {
  "layout.sidebar.dashboard": "Dashboard",
  "layout.sidebar.payments": "Payments",
  "layout.sidebar.payouts": "Payouts",
  "layout.sidebar.customers": "Customers",
  "layout.sidebar.statistique": "Statistics",
  "layout.sidebar.settings": "Settings",
  "layout.sidebar.team": "Team",
  "layout.sidebar.developers": "Developers",
  "layout.sidebar.sandbox": "Sandbox Mode",
  "layout.sidebar.enableApp.title": "Activate Your App",
  "layout.sidebar.enableApp.description":
    "Your app is not yet activated. Activate your app to put it online and receive payments from your customers.",
  "layout.sidebar.enableApp.button": "Activate the App",
  "layout.sidebar.transactions": "Transactions",
  "layout.sidebar.gateways": "Gateways",
  "layout.sidebar.methods": "Methods",
  "layout.sidebar.documentation": "Documentation",
  "layout.sidebar.helpCenter": "Helpcenter",
  "layout.sidebar.tutorials": "Tutorials",
};
