import { appPaths } from "@/configs";
import {
  Box,
  Button,
  Surface,
  Text,
  InputField,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import { CopyOutline, CopySuccessOutline } from "@axazara/raiton-icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAcceptStaffInvitation, useGetStaffInvitation } from "../../service";
import { Trans, useTranslation } from "react-i18next";
import { useClipboard } from "@axazara/raiton-utils";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useForm } from "@axazara/raiton-molecules";

export function Invitation() {
  const { invitationId } = useParams();

  if (!invitationId) return <Navigate to={appPaths.auth} />;

  return <InvitationRenderer invitationId={invitationId} />;
}

function InvitationRenderer({ invitationId }: { invitationId: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: staffInvitation } = useGetStaffInvitation(invitationId);
  const acceptInvitationMutation = useAcceptStaffInvitation(invitationId);
  const { copy, copied } = useClipboard();

  const otpForm = useForm({ initialValues: { otp_code: "" } });

  async function handleAcceptInvitation(values: { otp_code: string }) {
    try {
      const response = await acceptInvitationMutation.mutateAsync(values);
      showSuccessNotification((response as any).message);
      navigate(appPaths.login.path);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  if (staffInvitation) {
    return (
      <Box component="form" onSubmit={otpForm.onSubmit(handleAcceptInvitation)}>
        <Box>
          <Box className="text-center">
            <Text className="text-primary-40 text-heading-02-sm-bold mb-4">
              <Trans key="invite_welcome">
                <span className="text-neutral-30">Bienvenue </span>{" "}
                {staffInvitation?.first_name} {staffInvitation?.last_name}
              </Trans>
            </Text>
            <Box className="flex justify-center">
              <Text component="p" className="text-p-01-medium w-3/4 mb-16">
                {t("invite_instruction")}
              </Text>
            </Box>
          </Box>
          <Box className="text-center text-primary-40">
            <Text className="text-heading-03-sm-bold">
              {t("step_n", { num: 1 })}
            </Text>
            <Text className="text-heading-05-sm mb-12">
              {t("configure_two_step_verification")}
            </Text>
            <Text className="text-neutral-40 text-p-01-medium mb-8">
              {t("configure_two_step_verification_instruction")}
            </Text>
            <Box className="flex justify-center mb-9">
              <img
                src={staffInvitation.two_factor_secret_qr_code}
                width={150}
                height={150}
                alt=""
              />
            </Box>
          </Box>
          <Surface className="border border-neutral-80 p-8 mb-16">
            <Box>
              <Text className="mb-6">
                {t("enter_the_code_in_your_authenticator_app")}
              </Text>
              <Box className="flex justify-between mt-0.5">
                <Box>
                  <Text
                    component="p"
                    className="text-neutral-70 text-p-01-semibold mb-2.5"
                  >
                    {t("key")}
                  </Text>
                  <Text component="p" className="text-p-01-semibold">
                    {staffInvitation.two_factor_secret}
                  </Text>
                </Box>
                <Button
                  variant="outline"
                  onClick={() => copy(staffInvitation.two_factor_secret)}
                  size="xs"
                  rightIcon={
                    copied ? (
                      <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
                    ) : (
                      <CopyOutline className="w-4 h-4 text-primary-40" />
                    )
                  }
                >
                  {copied ? t("copied") : t("copy")}
                </Button>
              </Box>
            </Box>
          </Surface>
          <Box className="text-center text-primary-40">
            <Text className="text-heading-03-sm-bold">
              {t("step_n", { num: 2 })}
            </Text>
            <Text className="text-heading-05-sm mb-12">
              {t("finalize_account_setup")}
            </Text>
            <Box className="flex justify-center">
              <Text className="text-neutral-40 text-p-01-medium w-3/4 mb-8">
                {t("enter_the_code_generated_by_your_authenticator_app")}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box className="grid justify-center mb-20">
          <Box className="flex space-x-5 md:space-x-6">
            <Box className="flex flex-row">
              <InputField
                label={t("otp_code")}
                size="xs"
                {...otpForm.getInputProps("otp_code")}
              />
            </Box>
          </Box>
        </Box>
        <Box className="flex justify-center">
          <Button
            type="submit"
            fullWidth
            size="xs"
            loading={acceptInvitationMutation.isLoading}
          >
            {t("accept_invitation")}
          </Button>
        </Box>
      </Box>
    );
  }

  return <LoadingOverlay visible />;
}
