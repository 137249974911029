import { Box, Text, InputField, Select, Button } from "@axazara/raiton-atoms";
import {
  ProfileOutline,
  SmsFill,
  ShieldSecurityFill,
  ProfileTickFill,
} from "@axazara/raiton-icons";
import { useForm } from "@axazara/raiton-molecules";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { roles } from "@/configs/roles";
import { status } from "@/configs/status";
import { Staff } from "utilities";
import { useUpdateStaffProfile } from "../services";

interface StaffEditForm {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: string;
  is_active: boolean;
}

export function EditStaff({ staff }: { staff: Staff }) {
  const staffEditForm = useForm<StaffEditForm>({
    initialValues: {
      first_name: staff?.first_name,
      last_name: staff?.last_name,
      email: staff?.email,
      role: staff?.role,
      is_active: staff?.is_active ?? false,
    },
  });

  const useUpdateStaffProfileMutation = useUpdateStaffProfile(staff.id);

  async function handleSubmit(values: StaffEditForm) {
    try {
      if (
        values.first_name !== staff?.first_name ||
        values.last_name !== staff?.last_name ||
        values.email !== staff?.email ||
        values.role !== staff?.role ||
        values.is_active !== staff?.is_active
      ) {
        const response =
          await useUpdateStaffProfileMutation.mutateAsync(values);
        showSuccessNotification((response as any)?.message);
      }
    } catch (error) {
      showErrorNotification((error as any)?.response?.data?.message);
    }
  }

  return (
    <Box className="mx-auto max-w-xl px-8">
      <Text className="text-left text-heading-05-bold text-neutral-30 mb-8">
        Edit Staff member
      </Text>
      <Box className="mb-8">
        <Box
          onSubmit={staffEditForm.onSubmit(handleSubmit)}
          component="form"
          className="mb-12 space-y-6"
        >
          <InputField
            size="xs"
            label="First Name"
            icon={<ProfileOutline className="h-6 w-6 text-neutral-40" />}
            placeholder="Your First name"
            {...staffEditForm.getInputProps("first_name")}
          />
          <InputField
            size="xs"
            label="Last Name"
            icon={<SmsFill className="h-6 w-6 text-neutral-40" />}
            placeholder="Your Last name"
            {...staffEditForm.getInputProps("last_name")}
          />
          <InputField
            type="email"
            size="xs"
            label="Email"
            icon={<SmsFill className="h-6 w-6 text-neutral-40" />}
            placeholder="Your Email"
            {...staffEditForm.getInputProps("email")}
          />
          <Select
            data={roles}
            label="Role"
            type="text"
            size="xs"
            radius={12}
            icon={<ShieldSecurityFill className="h-6 w-6 text-neutral-40" />}
            {...staffEditForm.getInputProps("role")}
          />
          <Select
            {...staffEditForm.getInputProps("is_active")}
            data={status}
            label="Status"
            type="text"
            size="xs"
            radius={12}
            icon={<ShieldSecurityFill className="h-6 w-6 text-neutral-40" />}
          />

          <Box className="flex justify-center">
            <Button
              disabled={!staffEditForm.isDirty()}
              loading={useUpdateStaffProfileMutation.isLoading}
              type="submit"
              className="text-caption-semibold"
              leftIcon={<ProfileTickFill className="h-6 w-6" />}
            >
              Update information
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
