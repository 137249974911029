import { Box, Text, Avatar } from "@axazara/raiton-atoms";
import { User } from "utilities";

interface AvatarCardProps {
  user: Pick<User, "profile_photo_url" | "first_name" | "last_name" | "email">;
  withAvatar?: boolean;
}

export function AvatarCard({ user, withAvatar }: AvatarCardProps) {
  return (
    <Box className="md:flex-row flex flex-col items-center space-x-2">
      {withAvatar && <Avatar src={user.profile_photo_url} size={38} />}
      <Box>
        <Text className="text-p-01-semibold text-neutral-20">{`${user.first_name} ${user.last_name}`}</Text>
        <Text className="text-caption text-neutral-60">{user.email}</Text>
      </Box>
    </Box>
  );
}
