import {
  Box,
  Text,
  Avatar,
  Tabs,
  Divider,
  LoadingOverlay,
  ActionIcon,
} from "@axazara/raiton-atoms";
import {
  Profile2userFill,
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  Box1Outline,
} from "@axazara/raiton-icons";
import { UserInformationTab, UserAppsTab } from "./components";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "@/features/Users/services";
import { appPaths } from "@/configs";

export function UsersDetails() {
  const { id } = useParams();
  if (!id) {
    return <Navigate to={appPaths.users.path} />;
  }

  const { data: user, isError, isLoading: isUserLoading } = useGetUser(id);
  const navigate = useNavigate();

  if (isError) return <Navigate to={appPaths.users.path} />;

  return (
    <Box>
      <LoadingOverlay visible={isUserLoading} />
      <Box className="flex flex-col sticky top-0 z-20 rounded-xl bg-white mb-12">
        <Box className="flex md:p-12 space-x-4 pb-4">
          <Box className="rounded-full w-12 h-12 md:w-16 md:h-16 bg-primary-90 flex justify-center items-center">
            <Profile2userFill className="w-8 text-primary-40" />
          </Box>
          <Box className="md:mt-1 flex-1">
            <Text className="md:text-heading-04-sm-bold text-primary-50 text-heading-06-bold mb-2">
              Users
            </Text>
            <Text className="text-neutral-30 md:text-p-02-medium text-caption-medium">
              Ici, vous avez la possibilité de modifier vos informations
              personnelles.
            </Text>
          </Box>
        </Box>
        <Divider color="neutral.9" className="border-neutral-90" />
      </Box>
      <Box className="flex items-center gap-6 p-6">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20 mb-1.5">
              {user?.first_name} {user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">
              @{user?.id}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box>
        <Tabs
          styles={(theme) => ({
            tabsList: {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
              "@media (max-width: 670px)": {
                overflow: "auto hidden",
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="information"
        >
          <Box className="flex justify-center">
            <Tabs.List className="mb-8">
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="information"
                icon={<TaskSquareOutline className="w-6 h-6" />}
              >
                Information
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="apps"
                icon={<Box1Outline className="w-6 h-6" />}
              >
                Apps
              </Tabs.Tab>
            </Tabs.List>
          </Box>
          <Tabs.Panel value="information">
            {user && <UserInformationTab user={user} />}
          </Tabs.Panel>
          <Tabs.Panel value="apps">
            {user && <UserAppsTab user={user} />}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
