export const ApiEndpoints = {
  login: "/auth/staff/login",
  staffInvitation: "/auth/staff/invite/:invitationId",
  logout: "/staff/logout",
  profile: "/staff/profile",
  users: "/users",
  staffs: "/staffs/members",
  servers: "/apps/:appId/servers",
  activities: "/staffs/activities",
  invitations: "/staffs/invites",
  apiLogs: "/logs/api",
  customers: "/customers",
  templateModels: "/template-models",
  templateModelCategories: "/utils/template-categories",
  appTeam: "/apps/:appId/team/members",
  apps: "/apps",
};
