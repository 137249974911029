import { Box, Button, LoadingOverlay } from "@axazara/raiton-atoms";
import { useClipboard } from "@axazara/raiton-utils";
import {
  CopyOutline,
  CopySuccessOutline,
  ArrowLeft1Outline,
} from "@axazara/raiton-icons";
import React from "react";
import EmailEditor from "react-email-editor";
import { useUpdateTemplate, useGetTemplateModelJSON } from "../../service";
import { useQueryClient } from "@tanstack/react-query";
import {
  formatPath,
  showErrorNotification,
  showSuccessNotification,
} from "@/utils";
import { ApiEndpoints, appPaths } from "@/configs";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams } from "react-router-dom";

export function TemplateModelEditor() {
  const { templateId } = useParams();

  if (!templateId) return <Navigate to={appPaths.templates.path} />;

  return <TemplateEditorRenderer templateId={templateId} />;
}

function TemplateEditorRenderer({ templateId }: { templateId: string }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const emailEditorRef = React.useRef(null);
  const updateTemplateMutation = useUpdateTemplate(templateId);
  const { data: templateJSON } = useGetTemplateModelJSON(templateId);
  const [liveTempletJSON, setLiveTempletJSON] = React.useState<Record<
    string,
    any
  > | null>(null);
  const { copy, copied } = useClipboard();

  const handleExport = () => {
    (emailEditorRef?.current as any)?.editor?.exportHtml(
      async (data: { design: any; html?: any }) => {
        setLiveTempletJSON(data.design);
        try {
          (emailEditorRef?.current as any).editor.exportPlainText(
            async (plainTextData: { text: any }) => {
              const { design, html } = data;
              const { text } = plainTextData;
              const payload = {
                html_content: html,
                json_content: JSON.stringify(design),
                text_content: text,
              };
              const response = await updateTemplateMutation.mutateAsync(
                payload as any,
              );

              showSuccessNotification((response as any).message);

              queryClient.invalidateQueries([
                `${ApiEndpoints.templateModels}/${templateId}/html-content`,

                null,
              ]);
              queryClient.invalidateQueries([
                `${ApiEndpoints.templateModels}/${templateId}/json
                                    -content`,

                null,
              ]);
            },
          );
        } catch (error) {
          const errorResponse = (error as any).response;
          showErrorNotification((errorResponse as any).message);
        }
      },
    );
  };

  React.useEffect(() => {
    if (templateJSON) {
      if (typeof templateJSON === "string")
        setLiveTempletJSON(JSON.parse(templateJSON));
      else setLiveTempletJSON(templateJSON);
    }
  }, [templateJSON]);

  const handleCopy = () => {
    (emailEditorRef?.current as any).editor.exportHtml(
      async (data: { html: any }) => {
        copy(data.html);
      },
    );
  };

  const onReady = () => {
    (emailEditorRef?.current as any).editor.loadDesign(liveTempletJSON);
  };

  if (templateJSON)
    return (
      <Box className="p-6">
        <Box className="mb-4 flex justify-between items-center">
          <Button
            size="xs"
            variant="outline"
            component={Link}
            leftIcon={<ArrowLeft1Outline className="w-4 h-4" />}
            to={formatPath(appPaths.templateDetails.path, {
              templateId,
            })}
          >
            {t("back")}
          </Button>
          <Box className="flex flex-row items-center space-x-2">
            <Button
              size="xs"
              onClick={() => handleCopy()}
              variant="outline"
              leftIcon={
                copied ? (
                  <CopySuccessOutline className="w-4 h-4" />
                ) : (
                  <CopyOutline className="w-4 h-4" />
                )
              }
            >
              {copied ? t("copied") : t("copy_html")}
            </Button>
            <Button
              size="xs"
              loading={updateTemplateMutation.isLoading}
              onClick={() => handleExport()}
              className="min-w-[120px]"
            >
              {t("save")}
            </Button>
          </Box>
        </Box>
        <Box>
          <React.StrictMode>
            {liveTempletJSON && (
              <EmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                minHeight="72vh"
              />
            )}
          </React.StrictMode>
        </Box>
      </Box>
    );

  return <LoadingOverlay visible />;
}
