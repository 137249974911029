import { Button, Box, Text } from "@axazara/raiton-atoms";
import { DocumentText1Fill, CloseCircleOutline } from "@axazara/raiton-icons";
import { openModal, closeModal } from "@axazara/raiton-molecules";
import { useTranslation } from "react-i18next";
import { AddTemplateModal } from "./AddTemplateModal";

export function AddTemplateButton() {
  const { t } = useTranslation();
  return (
    <Button
      size="xs"
      leftIcon={<DocumentText1Fill className="w-4 h-4" />}
      onClick={() =>
        openModal({
          title: (
            <Box className="flex flex-row w-full justify-between items-center border-b pb-6 border-b-neutral-80">
              <Box className="flex space-x-4 items-center font-poppins">
                <Box className="rounded-full bg-primary-50 w-12 h-12 flex items-center justify-center">
                  <DocumentText1Fill className="w-6 h-6 text-white" />
                </Box>
                <Box className="text-3xl text-neutral-20">
                  <Text component="h1" className="font-semibold">
                    {t("add_template")}
                  </Text>
                </Box>
              </Box>
              <CloseCircleOutline
                onClick={() => closeModal("")}
                className="w-5 h-5 cursor-pointer"
              />
            </Box>
          ),
          styles: () => ({
            title: {
              width: "100%",
            },
          }),
          withCloseButton: false,
          size: "lg",
          children: <AddTemplateModal />,
        })
      }
    >
      {t("add_template_model")}
    </Button>
  );
}
