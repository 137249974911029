export default {
  "layout.sidebar.dashboard": "Tableau de bord",
  "layout.sidebar.payments": "Paiements",
  "layout.sidebar.payouts": "Transferts",
  "layout.sidebar.customers": "Clients",
  "layout.sidebar.statistique": "Statistique",
  "layout.sidebar.settings": "Paramètres",
  "layout.sidebar.team": "Équipe",
  "layout.sidebar.developers": "Développeurs",
  "layout.sidebar.sandbox": "Mode bac à sable",
  "layout.sidebar.enableApp.title": "Activez votre application",
  "layout.sidebar.enableApp.description":
    "Votre application n’est pas encore activée. Activez votre application pour la mettre en ligne et recevoir les paiements de vos clients.",
  "layout.sidebar.enableApp.button": "Activer l'application",
  "layout.sidebar.transactions": "Transactions",
  "layout.sidebar.gateways": "Passerelles",
  "layout.sidebar.methods": "Méthodes",
  "layout.sidebar.documentation": "Documentation",
  "layout.sidebar.helpCenter": "Centre d'aide",
  "layout.sidebar.tutorials": "Tutoriels",
};
