import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  QueryFunctionContext,
  QueryKey,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Api } from "./api";

export interface GetInfinitePagesInterface<T> {
  pagination: {
    current_page: number;
    first_page_url: string;
    prev_page_url: string;
    next_page_url: string;
    last_page_url: string;
    last_page: number;
    per_page: number;
    next_page: number;
    previous_page: number;
    total: number;
    count: number;
    path: string;
  };
  data: T;
}

export const fetcher = async <T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKey, any>): Promise<T> => {
  const [url, params] = queryKey;
  const requestParams = {
    page: pageParam,
    ...(params as any),
  };
  const res = await Api.get<T>(url as string, { params: requestParams });
  if ("pagination" in res) return res as T;
  return res.data as T;
};

export const useLoadMore = <T>(url: string | null, params?: object) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKey
  >([url!, params], fetcher, {
    getPreviousPageParam: (firstPage) =>
      firstPage?.pagination?.previous_page ?? undefined,
    getNextPageParam: (lastPage) =>
      lastPage?.pagination?.next_page ?? undefined,
  });
  return context;
};

export const usePrefetch = <T>(url: string | null, params?: object) => {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient.prefetchQuery<T, Error, T, QueryKey>(
      [url!, params],
      ({ queryKey, meta }) => fetcher({ queryKey, meta }),
    );
  };
};

export const useFetch = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKey>,
) => {
  const context = useQuery<T, Error, T, QueryKey>(
    [url!, params],
    ({ queryKey, meta }) => fetcher({ queryKey, meta }),
    {
      enabled: !!url,
      retry: false,
      ...config,
    },
  );

  return context;
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      queryClient.setQueryData<T>([url!, params], (oldData) =>
        updater ? updater(oldData!, data as S) : (data as T),
      );

      return previousData;
    },
    onError: (_err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
) =>
  useGenericMutation<T, string | number>(
    (id) => Api.delete(`${url}/${id}`),
    url,
    params,
    updater,
  );

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) =>
  useGenericMutation<T, S>(
    (data) => Api.post<S>(url, data),
    url,
    params,
    updater,
  );

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) =>
  useGenericMutation<T, S>(
    (data) => Api.put<S>(url, data),
    url,
    params,
    updater,
  );
