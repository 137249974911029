import { Box, Table, ActionIcon, Text, Avatar } from "@axazara/raiton-atoms";
import { SearchStatusOutline } from "@axazara/raiton-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatPath } from "@/utils";
import { appPaths } from "@/configs";
import { TemplateModels } from "utilities";

export function TemplateModelsTable({
  templates,
}: {
  templates: TemplateModels;
}) {
  const { t } = useTranslation();
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse border border-neutral-80 border-spacing-0">
        <Table.Head className="border-b border-neutral-80 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            {t("name")}
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">
            {t("subject")}
          </Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold w-20 align-middle text-center">
            {t("view")}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y border border-neutral">
          {templates.map((template) => (
            <Table.Row key={template.id} className="bg-white border-neutral-80">
              <Table.Cell className="whitespace-nowrap text-neutral-40">
                <Box className="flex items-center">
                  <Avatar src={template.preview_image_url} className="mr-3" />
                  <Box>
                    <Text
                      component="h1"
                      className="font-semibold text-black truncate"
                    >
                      {template.name}
                    </Text>
                  </Box>
                </Box>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap text-neutral-90">
                <Text className="text-neutral-40 font-semibold text-ellipsis overflow-hidden w-64">
                  {template.subject}
                </Text>
              </Table.Cell>

              <Table.Cell className="align-middle">
                <ActionIcon
                  component={Link}
                  to={formatPath(appPaths.templateDetails.path, {
                    templateId: template.id,
                  })}
                  color="primary"
                  size="xl"
                  radius={100}
                  className="p-0.5"
                >
                  <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                </ActionIcon>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}
