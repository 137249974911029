import { ApiEndpoints } from "@/configs";
import { useFetch } from "@/lib";
import { formatPath } from "@/utils";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  App,
  PaginatedAppTeamMembers,
  PaginatedApps,
  PaginatedServers,
} from "utilities";

export const useGetApps = (
  { page, search }: { page: number; search: string } = {
    page: 1,
    search: "",
  },
) => {
  const params = buildFetchParams({ page, search });

  return useFetch<PaginatedApps>(ApiEndpoints.apps, params);
};

export const useGetApp = (id: string) =>
  useFetch<App>(`${ApiEndpoints.apps}/${id}`);

export const useGetAppServers = (
  appId: string,
  {
    page,
    search,
  }: {
    page?: number;
    search?: string;
  },
) => {
  let params = buildFetchParams({ page, search });

  return useFetch<PaginatedServers>(
    formatPath(ApiEndpoints.servers, { appId }),
    params,
  );
};

export const useGetAppTeamMembers = (
  appId: string,
  { page, search }: { page?: number; search?: string },
) => {
  const requestedPage = search ? 1 : page;
  const params = buildFetchParams({ page: requestedPage, search });

  return useFetch<PaginatedAppTeamMembers>(
    formatPath(ApiEndpoints.appTeam, { appId }),
    params,
  );
};
