import {
  DocumentText1Fill,
  EyeFill,
  CopyFill,
  CopySuccessOutline,
  ArrowLeft1Outline,
  Edit2Fill,
} from "@axazara/raiton-icons";
import { useClipboard } from "@axazara/raiton-utils";
import {
  ActionIcon,
  Box,
  Button,
  LoadingOverlay,
  Text,
} from "@axazara/raiton-atoms";
import { Navigate, useParams } from "react-router";
import { appPaths } from "@/configs";
import { formatPath } from "@/utils";
import { ErrorFallbackUI } from "@/components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openModal } from "@axazara/raiton-molecules";
import { TemplatePreview } from "../../components/TemplatePreview";
import { TemplateInfosForm } from "../../components/TemplateInfosForm";
import { TemplateModel } from "utilities";
import { useGetTemplate } from "../../service";

export function TemplateModelDetails() {
  const { templateId } = useParams();

  if (!templateId) return <Navigate to={formatPath(appPaths.templates.path)} />;

  return <TemplateDetailsPage templateId={templateId} />;
}

function TemplateDetailsPage({ templateId }: { templateId: string }) {
  const { data: template, isError } = useGetTemplate(templateId);

  if (isError) return <ErrorFallbackUI />;

  if (template)
    return (
      <TemplateDetailsPageRenderer
        template={template}
        templateId={templateId}
      />
    );

  return <LoadingOverlay visible />;
}

function TemplateDetailsPageRenderer({
  template,
  templateId,
}: {
  template: TemplateModel;
  templateId: string;
}) {
  const { t } = useTranslation();
  const { copy, copied } = useClipboard();

  return (
    <Box className="px-6">
      <Box className="flex justify-between py-6 border-b border-neutral-80">
        <Box className="flex items-center space-x-2">
          <ActionIcon
            radius={100}
            component={Link}
            to={formatPath(appPaths.templates.path)}
          >
            <ArrowLeft1Outline className="w-4 h-4 text-neutral-60" />
          </ActionIcon>
          <Box className="rounded-full flex justify-center items-center bg-primary-50 w-10 h-10">
            <Box className="flex items-center p-2">
              <DocumentText1Fill className="w-5 h-5 text-neutral" />
            </Box>
          </Box>
          <Box className="mx-3">
            <Text className="text-heading-06-sm-semibold">{template.name}</Text>
            <Box className="flex space-x-4 items-center">
              <Text className="text-neutral-40 text-p-01">{templateId}</Text>
              <ActionIcon radius={100} onClick={() => copy(templateId)}>
                {copied ? (
                  <CopySuccessOutline className="w-3.5 h-3.5" />
                ) : (
                  <CopyFill className="w-3.5 h-3.5" />
                )}
              </ActionIcon>
            </Box>
          </Box>
        </Box>

        <Box className="space-x-4">
          <Button
            size="xs"
            variant="outline"
            onClick={() =>
              openModal({
                fullScreen: true,
                styles: () => ({
                  body: {
                    height: "90%",
                    "& div:first-of-type": {
                      height: "100%",
                    },
                  },
                }),
                children: <TemplatePreview templateId={templateId} />,
              })
            }
            leftIcon={<EyeFill className="w-6 h-6" />}
          >
            {t("preview")}
          </Button>
          <Button
            size="xs"
            component={Link}
            to={formatPath(appPaths.templateDetailsEdit.path, {
              templateId,
            })}
            leftIcon={<Edit2Fill className="w-6 h-6" />}
          >
            {t("edit")}
          </Button>
        </Box>
      </Box>
      <TemplateInfosForm template={template} />
    </Box>
  );
}
