import React from "react";
import {
  Box,
  Table,
  Text,
  InputField,
  ActionIcon,
  Tag,
  Pagination,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import {
  SearchNormal1Outline,
  ClockFill,
  CloseCircleOutline,
  SmsOutline,
  TickCircleFill,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { formatDate } from "utilities";
import { useGetInvitations } from "../services";

export function Invitations() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);
  const { data: invitations, isLoading } = useGetInvitations({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex justify-end mb-8 px-6">
        <InputField
          placeholder="Search invitation"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          className="w-80"
          onChange={(e) => setSearchQuery(e.target.value)}
          rightSection={
            debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-gray-600"
                onClick={() => setSearchQuery("")}
              />
            ) : null
          }
          classNames={{
            input: "rounded-xl",
          }}
        />
      </Box>
      <Box className="px-6">
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse border border-neutral-80 border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell className="w-20">ID</Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80">
              {invitations?.data &&
                invitations?.data.length > 0 &&
                invitations.data.map((invitation, index) => (
                  <Table.Row>
                    <Table.Cell>
                      <Text component="span" className="text-p-01-semibold">
                        {index + 1}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Box className="flex items-center space-x-2">
                        <Box>
                          <Text className="text-p-01-medium">
                            {invitation?.first_name} {invitation?.last_name}
                          </Text>
                          <Text className="text-neutral-40">
                            {invitation?.email}
                          </Text>
                        </Box>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Text
                        component="span"
                        className="text-neutral-40 text-p-01"
                      >
                        {formatDate(invitation.created_at, "en")}
                      </Text>
                    </Table.Cell>

                    <Table.Cell>
                      {invitation.staff?.is_active ? (
                        <Tag
                          variant="filled"
                          className="normal-case"
                          color="success.8"
                          size="lg"
                          leftSection={
                            <TickCircleFill className="w-4 h-4 text-success-30" />
                          }
                        >
                          <Text className="text-success-50 text-p-01">
                            Accepted
                          </Text>
                        </Tag>
                      ) : (
                        <Tag
                          variant="filled"
                          className="normal-case"
                          color="secondary.8"
                          size="lg"
                          leftSection={
                            <ClockFill className="w-3.5 h-3.5 text-secondary-50" />
                          }
                        >
                          <Text className="text-secondary-50 text-p-01">
                            Pending
                          </Text>
                        </Tag>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Box className="flex">
                        <ActionIcon
                          color="primary"
                          className="p-0.5"
                          size="xl"
                          radius={100}
                        >
                          <SmsOutline className="w-6 h-6" />
                        </ActionIcon>
                        <ActionIcon
                          color="error"
                          className="p-0.5"
                          size="xl"
                          radius={100}
                        >
                          <CloseCircleOutline className="w-6 h-6" />
                        </ActionIcon>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Box>
        <Box className="flex w-full justify-end items-center mb-16 pt-8 px-6">
          <Pagination
            size="xl"
            radius={0}
            spacing={0}
            color="green"
            initialPage={1}
            onChange={setPage}
            page={page}
            total={invitations?.pagination.last_page ?? 0}
            style={{ gap: 0 }}
            styles={() => ({
              item: {
                fontSize: 14,
                "&:focus": {
                  outline: "none",
                },
              },
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}
