import * as React from "react";

import { AppFrame, Surface, Box, LoadingOverlay } from "@axazara/raiton-atoms";

import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { PermissionsProvider } from "@/context";
import { useGetProfile } from "@/services";

export function RootLayout() {
  const [isSidebarOpened, setIsSidebarOpened] = React.useState(false);

  const { data: staff } = useGetProfile();

  if (staff) {
    return (
      <PermissionsProvider permissions={staff.permissions}>
        <AppFrame
          padding={0}
          styles={(theme) => ({
            root: {
              background: theme.colors.neutral[9],
            },
            body: {
              height: "100%",
              "@media (min-width: 767px)": {
                height: "100vh",
              },
              overflow: "hidden",
            },
          })}
          fixed={false}
          header={
            <Header
              opened={isSidebarOpened}
              onOpenSidebar={() => setIsSidebarOpened(true)}
            />
          }
          navbar={
            <Sidebar
              onClose={() => setIsSidebarOpened(false)}
              opened={isSidebarOpened}
            />
          }
        >
          <Box className="h-full md:p-8 p-4">
            <Surface
              component="main"
              radius={16}
              className="w-full overflow-y-auto relative md:h-[calc(100vh-64px)] h-[calc(100vh-88px)] rounded-2xl scrollbar-hide"
            >
              <Outlet />
            </Surface>
          </Box>
        </AppFrame>
      </PermissionsProvider>
    );
  }

  return (
    <Box className="w-full h-screen flex items-center justify-center">
      <LoadingOverlay visible className="w-40 h-40" />
    </Box>
  );
}
