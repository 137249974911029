import { ApiEndpoints } from "@/configs";
import { useFetch, usePost, useUpdate } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import { Activities, Invitations, Staff, Staffs } from "utilities";

export const useGetStaffsData = (
  { page, search }: { page: number; search: string } = {
    page: 1,
    search: "",
  },
) => {
  let params = buildFetchParams({ page, search });
  return useFetch<Staffs>(`${ApiEndpoints.staffs}`, params);
};

export const useGetActivities = (
  { page, search }: { page: number; search: string } = {
    page: 1,
    search: "",
  },
) => {
  let params = buildFetchParams({ page, search });

  return useFetch<Activities>(`${ApiEndpoints.activities}`, params, {
    keepPreviousData: true,
  });
};

export const useGetInvitations = (
  { page, search }: { page: number; search: string } = {
    page: 1,
    search: "",
  },
) => {
  const params = buildFetchParams({ page, search });

  return useFetch<Invitations>(`${ApiEndpoints.invitations}`, params);
};

export const useCreateStaff = () =>
  usePost<Partial<Staff>, Staff>(ApiEndpoints.staffs);

export const useUpdateStaffProfile = (id: string) =>
  useUpdate<Partial<Staff>, Staff>(`${ApiEndpoints.staffs}/${id}`);

export const useGetStaff = (id: string) =>
  useFetch<Staff>(`${ApiEndpoints.staffs}/${id}`);

export const useGetStaffActivities = (
  { page, search, id }: { page: number; search: string; id: string } = {
    page: 1,
    search: "",
    id: "",
  },
) => {
  const params = buildFetchParams({ page, search });
  return useFetch<Activities>(
    `${ApiEndpoints.staffs}/${id}/activities`,
    params,
  );
};
