import {
  Navbar,
  NavbarProps,
  Box,
  Drawer,
  Text,
  Divider,
  Avatar,
  Popover,
  Surface,
  Button,
} from "@axazara/raiton-atoms";

import { NavLink, useNavigate } from "react-router-dom";
import {
  LoginOutline,
  Profile2userOutline,
  PeopleOutline,
  Box1Outline,
  Element1Outline,
} from "@axazara/raiton-icons";
import { appPaths } from "@/configs";
import { useGetProfile, useLogout } from "@/services";
import { EphemeralStorage, PersistentStorage, useMediaQuery } from "@/utils";
import { AccessControl } from "@/context";
import { StaffPermissions } from "utilities";

interface NavigationProps extends Omit<NavbarProps, "children"> {
  isMobileView: boolean;
}

function Navigation({ withBorder = false, isMobileView }: NavigationProps) {
  const activeLinkClassNames = "bg-primary-90 text-primary-40 rounded-lg block";
  const defaultLinkClassNames =
    "hover:bg-primary-90 hover:text-primary-40 text-neutral-30 rounded-lg block";

  const navigate = useNavigate();
  const logoutMutation = useLogout();
  const { data: staff } = useGetProfile();

  const onLogout = async () => {
    await logoutMutation.mutateAsync(undefined);
    PersistentStorage.clear();
    EphemeralStorage.clear();
    navigate(appPaths.login.path);
  };

  return (
    <Navbar
      width={{ base: 276 }}
      withBorder={withBorder}
      className="pl-4 pr-3.5 lg:pt-10 xl:pt-10 pb-12 flex flex-col justify-between overflow-y-auto"
    >
      <Box>
        <Box className="mb-12">
          <img
            src="https://cdn.axazara.com/brand/mailzeet/logo.svg"
            alt="mailzeet"
            width={136}
            height={32}
          />
        </Box>
        <Box className="space-y-4">
          <NavLink
            to={appPaths.users.path}
            className={({ isActive }) =>
              isActive ? activeLinkClassNames : defaultLinkClassNames
            }
          >
            <Box className="space-x-2.5 flex flex-row items-center p-2.5">
              <Profile2userOutline className="w-6 h-6" />
              <Text className="text-p-01-medium">Users</Text>
            </Box>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClassNames : defaultLinkClassNames
            }
            to={appPaths.templates.path}
          >
            <Box className="space-x-2.5 flex flex-row items-center p-2.5">
              <Element1Outline className="w-6 h-6" />
              <Text className="text-p-01-medium">Templates models</Text>
            </Box>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClassNames : defaultLinkClassNames
            }
            to={appPaths.apps.path}
          >
            <Box className="space-x-2.5 flex flex-row items-center p-2.5">
              <Box1Outline className="w-6 h-6" />
              <Text className="text-p-01-medium">Apps</Text>
            </Box>
          </NavLink>

          <AccessControl
            permissions={[
              StaffPermissions.CREATE_STAFFS,
              StaffPermissions.UPDATE_STAFFS,
              StaffPermissions.READ_STAFFS,
            ]}
          >
            <NavLink
              className={({ isActive }) =>
                isActive ? activeLinkClassNames : defaultLinkClassNames
              }
              to={appPaths.staffs.path}
            >
              <Box className="space-x-2.5 flex flex-row items-center p-2.5">
                <PeopleOutline className="w-6 h-6" />
                <Text className="text-p-01-medium">Staffs</Text>
              </Box>
            </NavLink>
          </AccessControl>
        </Box>
      </Box>

      <Box className="px-4 mt-8">
        <Divider className="border-neutral-60 mb-4" />
        <Popover position={isMobileView ? "top-start" : "right-start"}>
          <Popover.Target>
            <Box className="flex flex-row items-center">
              <Avatar
                src={staff?.profile_photo_url}
                size={48}
                className="mr-4"
              />
              <Box className="flex justify-center space-x-3">
                <Text className="text-p-01-semibold text-neutral-30">
                  {staff?.first_name} {staff?.last_name}
                </Text>
              </Box>
            </Box>
          </Popover.Target>
          <Popover.Dropdown className="border-none p-0 md:ml-10 -mt-8 bg-transparent">
            <Surface radius={24} shadow="lg" className="p-0">
              <Box className="px-6 py-4 space-y-5">
                <Text className="text-heading-06-sm-bold">Compte</Text>
                <Box className="flex flow-row items-center">
                  <Avatar
                    src={staff?.profile_photo_url}
                    size={48}
                    className="mr-2"
                  />
                  <Box>
                    <Text className="text-p-01-semibold">
                      {staff?.first_name} {staff?.last_name}
                    </Text>
                    <Text className="text-p-01-medium text-neutral-60">
                      {staff?.email}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Divider className="border-neutral-80" />
              <Button
                size="md"
                variant="subtle"
                leftIcon={<LoginOutline className="w-6 h-6" />}
                onClick={onLogout}
                loading={logoutMutation.isLoading}
                className="hover:bg-transparent text-neutral-30 text-p-01-medium"
              >
                Logout
              </Button>
            </Surface>
          </Popover.Dropdown>
        </Popover>
      </Box>
    </Navbar>
  );
}

interface SidebarProps {
  opened: boolean;
  onClose: () => void;
}

export function Sidebar({ opened, onClose }: SidebarProps) {
  const isMobileView = useMediaQuery("(max-width: 767px)");

  if (isMobileView) {
    return (
      <Drawer
        opened={opened}
        withCloseButton={false}
        onClose={onClose}
        overlayColor="#0B0C0E1E"
        classNames={{ drawer: "w-[272px]" }}
      >
        <Navigation isMobileView={isMobileView} />
      </Drawer>
    );
  }

  return <Navigation isMobileView={isMobileView} withBorder />;
}
