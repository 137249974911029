import { Box, LoadingOverlay } from "@axazara/raiton-atoms";
import { useGetTemplateModelHTML } from "../service";

export function TemplatePreview({ templateId }: { templateId: string }) {
  const { data: templateHTMLObj } = useGetTemplateModelHTML(templateId);

  if (templateHTMLObj) {
    return (
      <Box dangerouslySetInnerHTML={{ __html: templateHTMLObj ?? <div /> }} />
    );
  }

  return <LoadingOverlay visible />;
}
