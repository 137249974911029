import { ApiEndpoints } from "@/configs";
import { useDelete, useFetch, usePost, useUpdate } from "@/lib";
import { SelectItem } from "@axazara/raiton-atoms";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  AddTemplateModelPayload,
  PaginatedTemplateModels,
  TemplateModel,
} from "utilities";

export const useGetTemplateModels = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({ page, search });
  return useFetch<PaginatedTemplateModels>(
    ApiEndpoints.templateModels,
    params,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: "always",
    },
  );
};

export const useGetTemplateModelCategories = ({
  search,
}: {
  search?: string;
}) => {
  const params = buildFetchParams({ search });
  return useFetch<SelectItem[]>(ApiEndpoints.templateModelCategories, params);
};

export const useGetTemplateModel = (id: string) => {
  return useFetch<TemplateModel>(
    `${ApiEndpoints.templateModels}/${id}`,
    undefined,
    {
      refetchOnWindowFocus: "always",
    },
  );
};

export const useGetTemplateModelHTML = (id: string) => {
  return useFetch<string>(
    `${ApiEndpoints.templateModels}/${id}/html-content`,
    undefined,
    { enabled: !!id },
  );
};

export const useGetTemplateModelJSON = (id: string, initialData?: string) => {
  return useFetch<string>(
    `${ApiEndpoints.templateModels}/${id}/json-content`,
    undefined,
    { enabled: !!id, initialData },
  );
};

export const useGetTemplate = (id: string) => {
  return useFetch<TemplateModel>(
    `${ApiEndpoints.templateModels}/${id}`,
    undefined,
    {
      refetchOnWindowFocus: "always",
    },
  );
};

export const useAddTemplateModel = () =>
  usePost<AddTemplateModelPayload, TemplateModel>(ApiEndpoints.templateModels);

export const useUpdateTemplate = (id: string) =>
  useUpdate<Partial<TemplateModel>, TemplateModel>(
    `${ApiEndpoints.templateModels}/${id}`,
  );

export const useDeleteTemplate = (id: string) =>
  useDelete(`ApiEndpoints.templateModels}/${id}`);
