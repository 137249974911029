import { Box, Burger } from "@axazara/raiton-atoms";
import { useMediaQuery } from "@/utils";

interface HeaderProps {
  opened: boolean;
  onOpenSidebar: () => void;
}

export function Header({ opened, onOpenSidebar }: HeaderProps) {
  const isMobileView = useMediaQuery("(max-width: 767px)");

  if (isMobileView) {
    return (
      <Box className="flex justify-start pt-4 mx-4">
        <Box className="text-neutral-90 w-6 h-6 mr-5">
          <Burger
            styles={(theme) => ({
              burger: {
                backgroundColor: `${theme.colors.neutral[8]}!important`,
                "&:after": {
                  backgroundColor: theme.colors.neutral[8],
                  width: 14,
                },
                "&:before": {
                  backgroundColor: theme.colors.neutral[8],
                  width: 14,
                },
              },
            })}
            classNames={{
              root: "text-neutral rounded-lg  bg-neutral-10 p-1.5 flex items-center",
            }}
            size={16}
            opened={opened}
            onClick={onOpenSidebar}
          />
        </Box>
        <Box>
          <img
            src="/assets/images/mailzeet-logo.svg"
            alt="mailzeet"
            width={102}
            height={24}
          />
        </Box>
      </Box>
    );
  }

  return <Box />;
}
