import { Box, Text } from "@axazara/raiton-atoms";
import { App, formatDate } from "utilities";

export function AppInformationTab({ app }: { app: App }) {
  return (
    <Box className="max-w-xl w-full mx-auto space-y-4 mt-12">
      <Box className="flex flex-row items-center justify-between space-x-20 text-neutral-30">
        <Text className="text-p-01-semibold mb-2 w-40">App name</Text>
        <Text className="text-p-02-medium">{app.name}</Text>
      </Box>
      <Box className="flex flex-row items-center justify-between space-x-20 text-neutral-30">
        <Text className="text-p-01-semibold mb-2 w-40">App ID</Text>
        <Text className="text-p-02-medium">{app?.id}</Text>
      </Box>
      <Box className="flex flex-row items-center justify-between space-x-20 text-neutral-30">
        <Text className="text-p-01-medium mb-2 w-40">Created at</Text>
        <Text className="text-p-02-medium">
          {app?.created_at && formatDate(app?.created_at, "en")}
        </Text>
      </Box>
    </Box>
  );
}
