import {
  Box,
  InputField,
  Table,
  Tag,
  ActionIcon,
  Pagination,
  Text,
} from "@axazara/raiton-atoms";
import { openModal } from "@axazara/raiton-molecules";
import { useDebouncedValue } from "@axazara/raiton-utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { App, formatDate } from "utilities";
import { useGetAppTeamMembers } from "../services";

import {
  SearchNormal1Outline,
  CloseCircleFill,
  SearchStatusOutline,
  TickCircleFill,
} from "@axazara/raiton-icons";
import { MemberDetailsModal } from "./MemberDetailsModal";
import { AvatarCard } from "@/components";

export function AppTeamsTab({ app }: { app: App }) {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const { data: teamMembers } = useGetAppTeamMembers(app.id, {
    search: debouncedSearch,
    page,
  });
  const { t } = useTranslation();

  return (
    <Box className="pb-5">
      <Box className="flex gap-2 w-full my-5">
        <InputField
          classNames={{ input: "rounded-xl" }}
          radius={12}
          placeholder={t("search_team_member")}
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full"
          value={searchQuery}
          rightSection={
            debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-neutral-40"
                onClick={() => {
                  setSearchQuery("");
                }}
              />
            ) : null
          }
        />
      </Box>
      <Box className="w-full overflow-hidden border border-neutral-80 rounded-2xl">
        <Table className="rounded-t-2xl overflow-hidden border-collapse border">
          <Table.Head className="border-b border-neutral-80 text-neutral-20 !bg-neutral text-p-01-semibold font-poppins">
            <Table.HeadCell>{t("number")}</Table.HeadCell>
            <Table.HeadCell>{t("name")}</Table.HeadCell>
            <Table.HeadCell>{t("role")}</Table.HeadCell>
            <Table.HeadCell>{t("added_at")}</Table.HeadCell>
            <Table.HeadCell>{t("status")}</Table.HeadCell>
            <Table.HeadCell className="flex justify-end">
              {t("view")}
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y divide-neutral-80 font-poppins">
            {teamMembers?.data.map((teamMember, index) => (
              <Table.Row className="bg-white" key={teamMember.id}>
                <Table.Cell>
                  <Text className="text-p-01-medium">
                    {index + 1 + (page - 1) * 10}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <AvatarCard withAvatar user={teamMember.user} />
                </Table.Cell>
                <Table.Cell>
                  <Tag
                    variant="filled"
                    size="lg"
                    className="bg-primary normal-case text-primary-40 text-p-01-semibold"
                  >
                    {teamMember.role}
                  </Tag>
                </Table.Cell>
                <Table.Cell>
                  <Text className="text-p-01-medium text-neutral-40">
                    {teamMember.created_at && formatDate(teamMember.created_at)}
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  {teamMember.is_active ? (
                    <Tag
                      variant="filled"
                      size="lg"
                      leftSection={
                        <TickCircleFill className="w-4 h-4 text-success-30" />
                      }
                      className="bg-success-90 normal-case text-success-30 text-p-01-semibold"
                    >
                      {t("active")}
                    </Tag>
                  ) : (
                    <Tag
                      className="text-p-01 normal-case bg-critical-50 py-3"
                      size="lg"
                      leftSection={
                        <CloseCircleFill className="w-4 h-4 text-white" />
                      }
                    >
                      <Text component="span" className="text-white">
                        {t("inactive")}
                      </Text>
                    </Tag>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Box className="cursor-pointer flex justify-end">
                    <ActionIcon
                      color="primary"
                      className="p-0.5"
                      size="xl"
                      radius={100}
                      onClick={() =>
                        openModal({
                          title: t("view_team_member"),
                          centered: true,
                          children: <MemberDetailsModal member={teamMember} />,
                        })
                      }
                    >
                      <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                    </ActionIcon>
                  </Box>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Box>
      <Box className="flex w-full justify-between items-center mt-8 pb-8">
        <Text className="text-p-01-medium text-neutral-60">
          {t("pagination", {
            from: page,
            to: teamMembers?.pagination.last_page,
            total: teamMembers?.pagination.total,
          })}
        </Text>
        <Pagination
          size="xl"
          radius={0}
          spacing={0}
          color="green"
          page={page}
          initialPage={1}
          total={teamMembers?.pagination.last_page ?? 0}
          onChange={setPage}
          style={{ gap: 0 }}
          styles={() => ({
            item: {
              fontSize: 14,
              "&:focus": {
                outline: "none",
              },
            },
          })}
        />
      </Box>
    </Box>
  );
}
