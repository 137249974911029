import {
  Box,
  Text,
  Divider,
  Tabs,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import {
  ProfileOutline,
  FrameFill,
  ArrowSquareLeftOutline,
} from "@axazara/raiton-icons";
import { Link, Navigate, useParams } from "react-router-dom";
import { EditStaff } from "../components/EditStaff";
import { appPaths } from "@/configs";
import { useGetStaff } from "../services";
import { StaffActivities } from "../components/StaffActivities";

export function StaffDetails() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.staffs.path} />;
  }

  return <StaffDetailsRenderer id={id} />;
}

function StaffDetailsRenderer({ id }: { id: string }) {
  const { data: staff, isLoading, isError } = useGetStaff(id);

  if (isError) return <Navigate to={appPaths.staffs.path} />;

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex flex-col sticky top-0 bg-white z-20 rounded-xl mb-12">
        <Box className="flex md:p-12 pt-8 space-x-4 pb-4">
          <Box component={Link} to="/staffs" className="flex items-center">
            <ArrowSquareLeftOutline className="w-8 h-8 text-neutral-30" />
          </Box>
          <Box className="flex items-center space-x-4">
            <Box className="rounded-full flex justify-center bg-secondary-50 w-14 h-14">
              <Box className="flex items-center p-5">
                <FrameFill className="w-6 h-6 text-neutral" />
              </Box>
            </Box>
            <Box className="md:mt-1 flex-1">
              <Text className="text-heading-04-sm-bold text-primary-50 mb-2">
                {staff?.full_name}
              </Text>
              <Text className="text-neutral-30 text-p-02-medium">
                {staff?.email}
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider className="border-neutral-80" />
      </Box>
      <Box className="pb-12">
        <Tabs
          styles={(theme) => ({
            tabsList: {
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
              "@media (max-width: 670px)": {
                overflow: "auto hidden",
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="staff_edit"
        >
          <Tabs.List className="max-w-xs mx-auto w-60 flex justify-between mb-12">
            <Tabs.Tab
              icon={<ProfileOutline className="w-5 h-5 text-primary-4" />}
              value="staff_edit"
              className="text-p-01-semibold"
            >
              Staff Edit
            </Tabs.Tab>
            <Tabs.Tab
              icon={<ProfileOutline className="w-5 h-5 text-primary-4" />}
              value="staff_activity"
              className="text-p-01-semibold"
            >
              Staff Activity
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="staff_edit">
            {staff && <EditStaff staff={staff} />}
          </Tabs.Panel>
          <Tabs.Panel value="staff_activity">
            <StaffActivities />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
