import { Box, Text, Divider, Tabs } from "@axazara/raiton-atoms";
import { PeopleFill, SmsOutline, DocumentOutline } from "@axazara/raiton-icons";
import { Activities, Invitations, StaffsTab } from "../components";
import { AccessControl } from "@/context";
import { StaffPermissions } from "utilities";

export function Staffs() {
  return (
    <Box
      component={AccessControl}
      permissions={[
        StaffPermissions.CREATE_STAFFS,
        StaffPermissions.UPDATE_STAFFS,
        StaffPermissions.READ_STAFFS,
      ]}
      isPage
    >
      <Box className="flex flex-col sticky top-0 bg-white z-20 rounded-xl mb-12">
        <Box className="flex md:p-12 pt-8 space-x-4 pb-4">
          <Box className="rounded-full w-16 h-16 bg-primary-90 flex justify-center items-center">
            <PeopleFill className="w-8 text-primary-40" />
          </Box>
          <Box className="md:mt-1 flex-1">
            <Text className="text-heading-04-sm-bold text-primary-50 mb-2">
              Staffs
            </Text>
            <Text className="text-neutral-30 text-p-02-medium">
              All about the staffs
            </Text>
          </Box>
        </Box>
        <Divider className="border-neutral-80" />
      </Box>
      <Box className="pb-5">
        <Tabs
          styles={(theme) => ({
            tabsList: {
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: "nowrap",
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="staffs"
        >
          <Tabs.List className="max-w-md mx-auto w-full gap-5 text-p-01-semibold mb-12">
            <Tabs.Tab
              icon={<PeopleFill className="w-5 h-5 text-primary-4" />}
              value="staffs"
            >
              Staffs member
            </Tabs.Tab>
            <Tabs.Tab
              icon={<SmsOutline className="w-5 h-5 text-primary-4" />}
              value="invitations"
            >
              Invitations
            </Tabs.Tab>
            <Tabs.Tab
              icon={<DocumentOutline className="w-5 h-5 text-primary-4" />}
              value="activities"
            >
              Activities
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="staffs">
            <StaffsTab />
          </Tabs.Panel>
          <Tabs.Panel value="invitations">
            <Invitations />
          </Tabs.Panel>
          <Tabs.Panel value="activities">
            <Activities />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
