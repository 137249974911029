import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { formatPath } from "@/utils";
import { LoginPayload, LoginResponse, PendingInvitation } from "utilities";

export const useLoginWithOTP = () =>
  usePost<LoginPayload, LoginResponse>(ApiEndpoints.login);

export const useGetStaffInvitation = (invitationId: string) =>
  useFetch<PendingInvitation>(
    formatPath(ApiEndpoints.staffInvitation, { invitationId }),
  );

export const useAcceptStaffInvitation = (invitationId: string) =>
  usePost<{ otp_code: string }, { message: string }>(
    formatPath(ApiEndpoints.staffInvitation, { invitationId }),
  );
