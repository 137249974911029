import React from "react";
import { useGetApps } from "../services";
import {
  Box,
  LoadingOverlay,
  Divider,
  InputField,
  Table,
  Avatar,
  ActionIcon,
  Pagination,
  Text,
} from "@axazara/raiton-atoms";
import {
  SearchStatusOutline,
  Box1Outline,
  SearchNormal1Outline,
  CloseCircleFill,
} from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Apps() {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const searchInputRef = React.useRef(null);
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const {
    data: apps,
    isLoading,
    isError,
    error,
  } = useGetApps({ page, search: debouncedSearch });

  if (isError) return <h1>Error {error.toString()}</h1>;

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex flex-col sticky top-0 z-20 rounded-xl bg-white mb-12">
        <Box className="flex p-6 space-x-4 pb-4">
          <Box className="rounded-full w-12 h-12 md:w-16 md:h-16 bg-primary-90 flex justify-center items-center">
            <Box1Outline className="w-6 text-primary-40" />
          </Box>
          <Box className="md:mt-1 flex-1">
            <Text className="md:text-heading-04-sm-bold text-primary-50 text-heading-06-bold mb-2">
              Apps
            </Text>
            <Text className="text-neutral-30 md:text-p-02-medium text-caption-medium">
              Apps
            </Text>
          </Box>
        </Box>
        <Divider color="neutral.9" className={" border-neutral-90"} />
      </Box>
      <Box className="p-5">
        <Box className="flex justify-end w-full mb-6">
          <InputField
            placeholder={`${t("component.search")}`}
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            className="w-80"
            onChange={(e) => setSearchQuery(e.target.value)}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-gray-600"
                  onClick={() => {
                    setSearchQuery("");
                    if (searchInputRef.current)
                      (searchInputRef.current as any).value = "";
                  }}
                />
              ) : null
            }
            classNames={{
              input: "rounded-xl",
            }}
          />
        </Box>
        <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
          <Table className="rounded-t-2xl overflow-hidden border-collapse border">
            <Table.Head className="border-b border-neutral-80 text-neutral-20 !bg-neutral text-p-01-semibold">
              <Table.HeadCell>ID</Table.HeadCell>
              <Table.HeadCell>{t("app")}</Table.HeadCell>
              <Table.HeadCell>{t("owner")}</Table.HeadCell>
              <Table.HeadCell className="flex justify-end">
                {t("view")}
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80">
              {apps?.data.length &&
                apps.data.map((app, index) => (
                  <Table.Row key={app.id} className="bg-white">
                    <Table.Cell>
                      <Text className="text-p-01-medium">{index + 1}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Box className="md:flex-row flex flex-col items-center space-x-4">
                        <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center">
                          <Box1Outline className="w-5 h-5 text-white" />
                        </Box>
                        <Box>
                          <Text className="text-p-01-semibold text-neutral-20">
                            {app.name}
                          </Text>
                        </Box>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box className="md:flex-row flex flex-col items-center space-x-4">
                        <Avatar src={app.owner.profile_photo_url} size={32} />
                        <Box>
                          <Text className="text-p-01-semibold">
                            {app.owner.first_name} {app.owner.last_name}
                          </Text>
                        </Box>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box
                        component={Link}
                        to={`/apps/${app.id}`}
                        className="cursor-pointer flex justify-end"
                      >
                        <ActionIcon
                          color="primary"
                          className="p-0.5"
                          size="xl"
                          radius={100}
                        >
                          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                        </ActionIcon>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Divider color="neutral.9" />
        </Box>
        <Box className="flex w-full justify-end mt-8 pb-8">
          <Pagination
            size="xl"
            radius={0}
            spacing={0}
            color="green"
            page={page}
            initialPage={1}
            total={apps?.pagination.last_page ?? 0}
            onChange={setPage}
            style={{ gap: 0 }}
            styles={() => ({
              item: {
                fontSize: 14,
                "&:focus": {
                  outline: "none",
                },
              },
            })}
          />
        </Box>
      </Box>
    </Box>
  );
}
