import * as React from "react";
import {
  InputField,
  LoadingOverlay,
  Pagination,
  Box,
  Text,
  Divider,
} from "@axazara/raiton-atoms";
import { useDebouncedValue } from "@axazara/raiton-utils";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  Element1Outline,
} from "@axazara/raiton-icons";
import { EmptyState } from "@/components";
import { useTranslation } from "react-i18next";
import { AddTemplateButton } from "../../components/AddTemplateButton";
import { TemplateModelsTable } from "../../components/TemplateModelsTable";
import { useSearchParams } from "react-router-dom";
import { useGetTemplateModels } from "../../service";

export function TemplatesModels() {
  const { t } = useTranslation();
  const { isLoading, data: templates } = useGetTemplateModels({});

  if (isLoading) return <LoadingOverlay visible />;

  if (templates && templates.data.length > 0)
    return (
      <Box component="section" className="w-full">
        <Box className="flex flex-col sticky top-0 z-20 rounded-xl bg-white mb-12">
          <Box className=" flex justify-between items-center p-6 pb-4">
            <Box className="flex space-x-4 ">
              <Box className="rounded-full w-12 h-12 md:w-16 md:h-16 bg-primary-90 flex justify-center items-center">
                <Element1Outline className="w-6 text-primary-40" />
              </Box>
              <Box className="md:mt-1 flex-1">
                <Text className="md:text-heading-04-sm-bold text-primary-50 text-heading-06-bold mb-2">
                  {t("template_models")}
                </Text>
                <Text className="text-neutral-30 md:text-p-02-medium text-caption-medium">
                  {t("templates")}
                </Text>
              </Box>
            </Box>
            <AddTemplateButton />
          </Box>
          <Divider className="border-neutral-80 mt-4" />
        </Box>

        <TemplatesDataRenderer />
      </Box>
    );

  return (
    <EmptyState
      title={t("no_template_model_added_yet")}
      primaryAction={<AddTemplateButton />}
    />
  );
}

function TemplatesDataRenderer() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = React.useState(1);

  const [searchQuery, setSearchQuery] = React.useState(
    searchParams.get("search") ?? "",
  );
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const { data: templates } = useGetTemplateModels({
    page: currentPage,
    search: debouncedSearch,
  });

  return (
    <Box className="p-6">
      <Box className="justify-end flex w-full mb-8">
        <Box className="max-w-md w-full">
          <InputField
            size="xs"
            placeholder={t("search_templates")}
            className="w-full"
            icon={<SearchNormal1Outline className="w-5 h-5 text-neutral-60" />}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-gray-600"
                  onClick={() => {
                    setSearchQuery("");
                  }}
                />
              ) : null
            }
            onChange={(e) => {
              setSearchParams({ search: e.target.value });
              setSearchQuery(e.target.value);
            }}
          />
        </Box>
      </Box>
      {templates && <TemplateModelsTable templates={templates.data} />}
      <Box className="flex w-full justify-end my-10">
        {templates?.pagination && (
          <Pagination
            size="xl"
            radius={0}
            spacing={0}
            color="green"
            page={currentPage}
            initialPage={0}
            total={templates?.pagination.last_page}
            onChange={setCurrentPage}
            style={{ gap: 0 }}
            styles={() => ({
              item: {
                fontSize: 14,
                "&:focus": {
                  outline: "none",
                },
              },
            })}
          />
        )}
      </Box>
    </Box>
  );
}
