export const appPaths = {
  root: "/",
  auth: "/auth",
  login: { path: "/auth/login", name: "login" },
  invitation: { path: "/auth/invitation/:invitationId", name: "invitation" },
  users: { path: "/users", name: "users" },
  templates: { path: "/templates", name: "templates" },
  templateDetails: { path: "/templates/:templateId", name: "templates" },
  templateDetailsEdit: {
    path: "/templates/:templateId/editor",
    name: "templates",
  },
  usersDetails: { path: "/users/:id", name: "users-details" },
  apps: { path: "/apps", name: "apps" },
  appDetails: { path: "/apps/:id", name: "/apps-details" },
  customersInformation: {
    path: "/customers/:id",
    name: "customers-information",
  },
  app: { path: "/app", name: "app" },
  staffs: { path: "/staffs", name: "staffs" },
  StaffDetails: { path: "/staffs/:id", name: "staffs" },
};
