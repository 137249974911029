import { Box, Text, Tag } from "@axazara/raiton-atoms";
import { User, formatDate } from "utilities";
import { useTranslation } from "react-i18next";

export function UserInformationTab({ user }: { user: User }) {
  const { t } = useTranslation();
  return (
    <Box className="max-w-xs mx-auto">
      <Box className="space-y-4">
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">ID</Text>
          <Text className="text-neutral-30 text-p-01">{user?.id}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.firstName")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.first_name}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.lastName")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.last_name}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.email")}
          </Text>
          <Text className="text-neutral-30 text-p-01">{user?.email}</Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.signAt")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.created_at && formatDate(user?.created_at, "en")}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.lastLogin")}
          </Text>
          <Text className="text-neutral-30 text-p-01">
            {user?.last_login_at && formatDate(user?.last_login_at, "en")}
          </Text>
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            Identity status
          </Text>
          {user?.identity_is_verified ? (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                {t("component.verified")}
              </Text>
            </Tag>
          ) : (
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                {t("component.unverified")}
              </Text>
            </Tag>
          )}
        </Box>
        <Box className="flex flex-row items-center justify-between">
          <Text className="text-neutral-50 text-p-01-medium w-40">
            {t("component.2faStatus")}
          </Text>
          <Box>
            <Tag
              className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
              size="lg"
            >
              <Text
                component="span"
                className="text-success-30 text-p-01-semibold"
              >
                {t("component.enable")}
              </Text>
            </Tag>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
