import { Avatar, Box, Text } from "@axazara/raiton-atoms";
import { App } from "utilities";

export function AppOwnerTab({ app }: { app: App }) {
  return (
    <Box className="mt-12">
      <Box className="max-w-xl w-full mx-auto space-y-4 flex flex-col justify-center items-center">
        <Avatar src={app.owner.profile_photo_url} />
        <Box>
          <Box className="flex flex-row items-center justify-between space-x-20">
            <Text className="text-p-01-semibold w-40">Name</Text>
            <Text className="text-neutral-30 text-p-02-medium">
              {app.owner.full_name}
            </Text>
          </Box>{" "}
          <Box className="flex flex-row items-center justify-between space-x-20">
            <Text className="text-p-01-semibold w-40">Email</Text>
            <Text className="text-neutral-30 text-p-02-medium">
              {app.owner.email}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
