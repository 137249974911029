import React from "react";
import {
  Box,
  InputField,
  Table,
  Text,
  Tag,
  ActionIcon,
  Pagination,
  LoadingOverlay,
} from "@axazara/raiton-atoms";
import { openModal } from "@axazara/raiton-molecules";
import {
  SearchNormal1Outline,
  CloseCircleFill,
  SearchStatusOutline,
} from "@axazara/raiton-icons";
import { useDebouncedValue } from "@axazara/raiton-utils";
import { formatDate } from "utilities";
import { ActivityDetails } from "./ActivityDetails";
import { useGetActivities } from "../services";

export function Activities() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: activities, isLoading } = useGetActivities({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex justify-end px-6 mb-8">
        <InputField
          placeholder="Search activity"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          className="w-80"
          onChange={(e) => setSearchQuery(e.target.value)}
          rightSection={
            debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-gray-600"
                onClick={() => setSearchQuery("")}
              />
            ) : null
          }
          classNames={{
            input: "rounded-xl",
          }}
        />
      </Box>
      <Box className="px-6">
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse border border-neutral-80 border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell>ID</Table.HeadCell>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell className="flex justify-end">View</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80">
              {activities?.data?.length &&
                activities?.data.map((activity, index) => (
                  <Table.Row key={activity.id}>
                    <Table.Cell>
                      <Text component="span" className="text-p-01-semibold">
                        {index + 1 + (page - 1) * 10}
                      </Text>
                    </Table.Cell>
                    <Table.Cell className="w-40">
                      <Text component="span" className="text-p-01-semibold">
                        {formatDate(activity.created_at, "en")}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Tag
                        variant="filled"
                        color="primary.8"
                        className="normal-case"
                        size="lg"
                      >
                        <Text className="text-primary-40 text-p-01-semibold">
                          {" "}
                          {activity.action}
                        </Text>
                      </Tag>
                    </Table.Cell>
                    <Table.Cell className="flex justify-end">
                      <ActionIcon
                        onClick={() =>
                          openModal({
                            size: "lg",
                            children: <ActivityDetails activity={activity} />,
                          })
                        }
                        color="primary"
                        className="p-0.5"
                        size="xl"
                        radius={100}
                      >
                        <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                      </ActionIcon>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Box>
      </Box>
      <Box className="flex w-full justify-end mt-8 pb-8 pt-8 px-6">
        <Pagination
          size="xl"
          radius={0}
          spacing={0}
          page={page}
          initialPage={1}
          total={activities?.pagination.last_page ?? 0}
          onChange={setPage}
          style={{ gap: 0 }}
          styles={() => ({
            item: {
              fontSize: 14,
              "&:focus": {
                outline: "none",
              },
            },
          })}
        />
      </Box>
    </Box>
  );
}
